/* Reset some default styles */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  background-color: var(--primary-bg-color);
  color: var(--primary-text-color);
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Set a minimum height for the body */
}

main {
  display: flex;
  flex: 1;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.main-container {
  display: flex;
  flex: 1;
  max-width: 800px;
  flex-direction: column;
  height: 75vh;
}

.onboarding-container {
  flex-grow: 1; /* Allow the container to grow and take up remaining space */
  margin: 0 auto;
  text-align: center;
  background-color: var(--content-bg-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  background-color: var(--header-bg-color);
  padding: 1rem;
}

.main-heading {
  font-size: 2.5rem;
  color: var(--primary-text-color);
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.content {
  margin-bottom: 2rem;
  padding: 1rem;
  color: var(--content-text-color);
}

.description {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.onboarding-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-input,
.form-select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-size: 1rem;
}

.cta-button {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  text-decoration: none;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.cta-button:hover {
  background-color: var(--button-hover-bg-color);
}

.footer {
  margin-top: auto; /* Push the footer to the bottom */
  font-size: 0.9rem;
  padding: 1rem;
  background-color: var(--footer-bg-color);
  color: var(--footer-text-color);
}

.contact a {
  color: var(--link-color);
  text-decoration: none;
}

.contact a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .main-container {
    max-width: 100%;
    padding: 1rem;
    height: auto;
  }

  .main-heading {
    font-size: 2rem;
  }

  .description {
    font-size: 1rem;
  }

  .cta-button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .main-heading {
    font-size: 1.5rem;
  }

  .description {
    font-size: 0.9rem;
  }

  .cta-button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  .header,
  .content,
  .footer {
    padding: 0.5rem;
  }

  .onboarding-container {
    box-shadow: none;
  }
}
