:root {
  --primary-bg-color: #005fac;
  --primary-text-color: #fff;
  --content-bg-color: #fff;
  --content-text-color: #000;
  --header-bg-color: #005fac;
  --button-bg-color: #ffcc00;
  --button-hover-bg-color: #ffc525;
  --button-text-color: #005fac;
  --testimonial-bg-color: #f5f5f5;
  --footer-bg-color: #005fac;
  --footer-text-color: #fff;
  --link-color: #ffcc00;
}

/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  color: #333;
}
