/* Reset some default styles */
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: Arial, sans-serif;
  background-color: var(--primary-bg-color);
  color: var(--primary-text-color);
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Set a minimum height for the body */
}

.landing-container {
  flex: 1; /* Allow the container to grow and take up remaining space */
  margin: 0 auto;
  text-align: center;
  background-color: var(--content-bg-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  background-color: var(--header-bg-color);
  padding: 1rem;
}

.logo {
  max-width: 500px;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}

.main-heading {
  font-size: 2.5rem;
  color: var(--primary-text-color);
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.content {
  margin-bottom: 2rem;
  padding: 1rem;
  color: var(--content-text-color);
  flex-direction: column;
  flex: 1; /* Allow content to take up remaining space */
}

.description {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.cta-button {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  text-decoration: none;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin-top: 2rem;
}

.cta-button:hover {
  background-color: var(--button-hover-bg-color);
}

.testimonial {
  margin-top: 2rem;
  padding: 1rem;
  background-color: var(--testimonial-bg-color);
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.testimonial blockquote {
  font-style: italic;
  margin-bottom: 1rem;
}

.testimonial cite {
  font-weight: bold;
}

.footer {
  font-size: 0.9rem;
  padding: 1rem;
  background-color: var(--footer-bg-color);
  color: var(--footer-text-color);
  text-align: center;
}

.contact a {
  color: var(--link-color);
  text-decoration: none;
}

.contact a:hover {
  text-decoration: underline;
}

/* Media queries for responsive design */
@media (max-width: 600px) {
  .main-heading {
    font-size: 2rem;
  }

  .description {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .cta-button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }

  .testimonial {
    margin-top: 1rem;
    padding: 0.75rem;
  }

  .logo {
    max-width: 300px;
  }
}
